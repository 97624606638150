import {createGitgraph, templateExtend} from "@gitgraph/js";

export function create() {
    const gitgraphElement = document.getElementById("graph-container");

    const gitgraph = createGitgraph(gitgraphElement,{
        orientation: 'vertical-reverse',
        template: templateExtend("metro", {
            colors: ['#6fa97a', '#3e6472', '#8d0d37'],
            branch: {
                label: {
                    display: false,
                },
            },
            commit: {
                message: {
                    displayAuthor: false,
                    displayBranch: false,
                    displayHash: false,
                    font: "16pt"
                },
                widthExtension: 5
            }
        })
    })

    drawScenario(gitgraph);

    const map = new Map();

    const observer = new MutationObserver((mutationsList, observer) => {
        for(const mutation of mutationsList) {
            map.set(mutation.attributeName, mutation.target.getAttribute(mutation.attributeName));

            if(map.size == 2) {
                observer.disconnect();

                makeSVGResponsive(
                    mutation.target, map
                );
            }
        }
    });

    observer.observe(gitgraphElement.firstChild, {
        attributes: true
    });
}

function drawScenario(gitgraph) {
    const branch1 = createBranch(gitgraph, "Conseil en transition numérique");
    branch1.commit("Diagnostic pour validation des besoins");

    const branch2 = createBranch(gitgraph, "Accompagnement à la transformation");
    branch2.commit("Identification des outils adaptés à l'équipe");

    const branch3 = createBranch(gitgraph, "Formation");
    branch3.commit("Ingénierie pédagogique");
    branch3.commit("Formation en situation de travail");

    branch2.merge(branch3, "Accompagnement humain & organisation du travail");
    branch2.commit("Bilan & amélioration suite à expérimentation");

    branch1.merge(branch2, "Réduction des coûts & qualité de vie au travail");
}

function createBranch(gitgraph, name) {
    return gitgraph.branch(name).commit("").tag(name);
}

function makeSVGResponsive(svg, map) {
    svg.removeAttribute('height');
    svg.removeAttribute('width');

    svg.setAttribute('preserveAspectRatio', 'xMinYMin meet');
    svg.setAttribute('viewBox', `0 0 ${map.get('width')} ${map.get('height')}`);
}